import React from 'react';
import Feature from '../components/feature/feature.js';
import { Button, Row, Col, Container } from 'react-bootstrap';
import './home.css';

import { BsCardChecklist, BsMap, BsSearch, BsBarChart } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import 'bootswatch/dist/pulse/bootstrap.min.css';

export default class Home extends React.Component {

  render() {    

    return (
      <div className="home-background">
        <div className="home-leader">
        
          <h1><b>Giggity</b><img src="/images/logo_small.png" style={{height: '150px'}} /></h1>
          <h2>Make The Most Of Your Gig Business</h2>

          <h3>Manage your tips, mileage, and earnings all from one easy to use app.</h3>
          <Row style={{maxWidth: '1000px', margin: '0 auto'}}>
            <Col style={{textAlign: 'center'}}><a href="https://apps.apple.com/us/app/giggity-job-tracking/id1553149274"><img src="/images/appstore.png" className="home-install-button" /></a></Col>
            <Col style={{textAlign: 'center'}}><a href="https://play.google.com/store/apps/details?id=com.giggity.giggityapp"><img src="/images/playstore.png" className="home-install-button" /></a></Col>
          </Row>
        </div>

        <div className="home-features">
          <Container>
            <Row>
              <Col md="12" lg="6"><Feature feature={{icon: <BsCardChecklist className="feature-icon" />, title: 'Track Orders', blurb: 'Track customer information, address, earnings, tips, and mileage all in one place.'}} /></Col>
              <Col md="12" lg="6"><Feature feature={{icon: <FaRegMoneyBillAlt className="feature-icon" />, title: 'Get Paid More', blurb: 'When you track all of your data, you take back the power to decide which jobs will pay you more and which jobs to avoid.'}} /></Col>            
            </Row>
            <Row>
              <Col md="12" lg="6"><Feature feature={{icon: <BsBarChart className="feature-icon" />, title: 'Customer Grade', blurb: 'Customer grading system that compares customers with each other so you always pick the right order to take.'}} /></Col>
              <Col md="12" lg="6"><Feature feature={{icon: <BsMap className="feature-icon" />, title: 'Map Integration', blurb: 'Integrated with Google Maps to make getting to the right place simple and easy'}} /></Col>

  
            </Row>

          </Container>
        </div>

        <div className="home-services">

          <div>Services Supported</div>
          <Container>
              <Row>
                <Col><img src="/images/shipt.png" className="home-icon" /></Col>
                <Col><img src="/images/instacart.png" className="home-icon" /></Col>
                <Col><img src="/images/ubereats.png" className="home-icon" /></Col>
                <Col><img src="/images/doordash.png" className="home-icon" /></Col>
                <Col><img src="/images/postmates.png" className="home-icon" /></Col>
                <Col><img src="/images/grubhub.png" className="home-icon" /></Col>
              </Row>
              </Container>

        </div>
          

        <div className="home-footer">
          &copy;2020 mygiggity.com<br />
          <a href="/privacy">Privacy Policy</a>
        </div>
        

      </div>

    );
  }
}