import React from 'react';
import './feature.css';

export default class Feature extends React.Component {

  render() {

    let { feature } = this.props;

    return (
      <div className="feature-wrapper">        
        <div className="feature-title">
          {feature.icon} {feature.title}
        </div>
        <div className="feature-blurb">
          {feature.blurb}
        </div>

      </div>
        
    );
  }
}